.match-listing {
  &__filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 1rem;
    margin-bottom: 1rem;

    .bp3-switch {
      margin-bottom: 0; }

    > * {
      margin-right: 1rem; } }

  &__refresh {
    margin-bottom: 1rem; }

  &__search {
    display: flex;
    flex-direction: row;
    align-items: center;

    button {
      white-space: nowrap;
      margin-left: 1rem; } }

  &__footer-actions {
    margin-top: 1rem; } }
