.date-time-field .DayPicker {
  margin: auto;
  text-align: center; }

.rc-time-picker {
  width: 20rem;

  & &-input {
    font-size: 1.5rem;
    height: 2.5rem;
    text-align: center;
    margin-bottom: 1rem; } }

.rc-time-picker-panel {
  width: 20rem !important;
  max-width: initial !important;
  text-align: center;

  &-inner {
    margin-top: 2.5rem; }

  &-input {
    display: none;
    &-wrap {
      padding: 0 !important; } }

  &-select {
    direction: rtl; }

  &-select li {
    direction: ltr;
    font-size: 1.5rem;
    line-height: 2rem !important;
    height: 2rem !important;
    padding: 0 !important;
    text-align: center !important; } }

//.rc-time-picker-panel
//  .rc-time-picker-panel-select
//    max-height: 144px
//    overflow-y: auto
//    ul
//      max-height: initial
//
//.rc-time-picker-panel-combobox
//  display: flex
//  width: 170px
//
//.rc-time-picker-panel-select
//  flex: 1
//
//.rc-time-picker-input
//  width: 170px
//  font-size: 130%
//  text-align: center
//
//.rc-time-picker-panel-input
//  width: 100%
//  font-size: 130%
//  text-align: center
//
//.rc-time-picker-panel-inner
//  width: 100%
