@import "~normalize.css/normalize.css";
@import "~rc-time-picker/assets/index.css";
@import "~react-virtualized/styles.css";
@import "~react-dates/lib/css/_datepicker.css";

$icon-font-path: "~@blueprintjs/core/resources/icons";

$pt-font-family: "Share Tech";
$pt-intent-warning: #FD971F;
$pt-intent-danger: #DB3737;
$pt-dark-app-background-color: #282828;
$dark-card-background-color: lighten($pt-dark-app-background-color, 3%);
$dark-navbar-background-color: $dark-card-background-color;
$dark-popover-background-color: $dark-card-background-color;
$dark-input-background-color: $dark-card-background-color;
$dark-button-background-color: $dark-card-background-color;
$dark-popover-background-color: $dark-card-background-color;
$dark-menu-background-color: $dark-card-background-color;
$pt-text-selection-color: #A8A8A8;
$dark-input-shadow-color-focus: $pt-intent-danger;
$pt-dark-link-color: $pt-intent-danger;

@import "~@blueprintjs/core/src/blueprint.scss";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";

.bp3-dark .bp3-dialog-header {
  background-color: $dark-card-background-color; }

html, body, #root {
  width: 100%;
  height: 100%; }

.brand-logo {
  width: 32px;
  height: 32px;
  margin-right: 15px; }

.full-page {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: center;
  min-height: 100%;
  background-color: $pt-app-background-color;

  &.bp3-dark {
    background: $pt-dark-app-background-color; }

  &.navbar-sticky {
    margin-top: 44px; } }

.app-container {
  width: 100%;
  max-width: 920px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  > div {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column; } }

.ubl-link {
  font-weight: bold;
  text-transform: uppercase; }

.ubl-card {
  margin-top: 5px;
  margin-bottom: 5px; }

.current-time-unsynced {
  color: $pt-intent-danger; }

.required-star {
  color: $red4;
  padding-left: 5px; }

.opening-time {
  text-align: center;
  .bp3-callout {
    text-align: left; } }

.host-form fieldset {
  margin-bottom: 15px;
  padding: 10px; }

.host-form-actions {
  text-align: center; }

.host-form-row {
  display: flex;

  > * {
    flex: 1;
    margin: 10px; }

  &--tournament {

    > * {
      flex: initial; } } }

.home-page .bp3-card {
  margin-bottom: 5px;
  padding-bottom: 5px; }

.remove-modal-body {
  text-align: center;
  h5 {
    font-style: italic; } }

.removed-reason {
  text-align: center;
  color: $light-gray3;
  border-top: 1px solid;
  margin-top: 10px;

  div {
    padding-top: 5px; }

  .removed-reason-remover {
    font-style: italic; } }

.bp3-dark .bp3-card.time-settings {
  border-top: none; }

.bp3-card.time-settings {
  z-index: $pt-z-index-overlay + 10;
  border-top: none;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1px 0 0 0;

  .time-settings-popout {
    position: absolute;
    right: 0;
    > * {
      display: inline; } }
  .current-time {
    font-size: 22px;
    font-weight: bold; }
  .bp3-button {
    margin: 0;
    &:after {
      display: none; } } }

.navbar-sticky .bp3-card.time-settings {
  position: fixed;
  top: 0; }

.bp3-navbar {
  display: flex;
  justify-content: space-between; }

.match-moderation-actions {
  position: absolute;
  right: 0;
  bottom: 0; }

a.match-row-link:hover {
  text-decoration: none; }

.match-details__scenarios,
.match-tags,
.match-scenarios,
.server-tags, {
  .bp3-tag {
    margin: 5px; } }

.match-top-left-ribbon,
.match-top-right-ribbon {
  .bp3-tag {
    margin: 0 3px 0 3px; } }

.match-row {
  position: relative;
  margin-top: 20px;
  text-align: center;

  .match-content {
    margin-left: 20px; }

  .match-top-left-ribbon {
    position: absolute;
    top: -15px;
    left: -10px; }

  .match-top-right-ribbon {
    position: absolute;
    top: -15px;
    right: -10px; }

  .match-opens, .match-region {
    font-weight: bold; }

  .match-tags {
    padding-top: 7px;
    padding-bottom: 7px; } }

.bp3-card.bp3-intent-danger {
  background-color: $red1;
  opacity: .8; }

.bp3-card.application-footer {
  padding: 3px;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: space-between;

  .bp3-button-group .bp3-button {
    margin-right: 0; } }

.moderation-log {
  .moderation-log-entry {
    margin-top: 10px;
    display: block;

    > * {
      margin-right: 10px; }

    .moderation-log-entry-affected {
      font-weight: bold; } } }

.members-page button {
  margin-top: 15px; }

.members-page {
  display: flex;
  > div {
    min-width: 50%; } }

.editable-permission-node {
  &.permission-folder-node:hover, .letter-folder-node:hover {
    & > .bp3-tree-node-content {
      svg {
        color: $pt-intent-success; } } }

  .username-node:hover > .bp3-tree-node-content svg {
    color: $pt-intent-danger; } }

.hosting-rules {
  margin-bottom: 10px;
  padding-bottom: 0;
  cursor: pointer;

  > .bp3-collapse {
    cursor: auto; } }

.match-row-dialog {
  width: 75%; }

.date-time-field {
  position: relative; }

.ban-expiry-input {
  &.react-datepicker {
    flex-direction: column; }

  &_clear-button {
    margin-top: .5rem;
    margin-bottom: .5rem; } }

.rendered-markdown {
  padding-top: 10px;
  padding-bottom: 10px; }

.bp3-button-group.bp3-minimal > .bp3-button::after {
  margin: 0; }

a.username-link, span.username-link {
  border-bottom: 1px dotted;
  border-bottom-color: $pt-text-color;
  text-decoration: none;
  color: $pt-text-color;

  &:hover {
    color: $pt-text-color;
    border-bottom-style: solid; } }

.bp3-dark a.username-link, .bp3-dark span.username-link {
  color: $pt-dark-text-color;
  border-bottom-color: $pt-dark-text-color;

  &:hover {
    color: $pt-dark-text-color; } }

.bp3-dark {
  .bp3-card, .bp3-navbar, .bp3-dialog, .navbar-sticky .bp3-card.time-settings {
    border-top: 3px solid $pt-intent-danger; } }

.bp3-card, .bp3-navbar, .bp3-dialog, .navbar-sticky .bp3-card.time-settings {
  border-top: 3px solid $pt-intent-primary;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.match-details {
  .match-details__header {
    position: relative;
    margin-bottom: 10px;

    .match-details__header__floating-tags__top {
      position: relative;
      top: -35px;
      left: -35px; }

    .match-details__header__content {
      position: relative;
      top: -20px;
      text-align: center; }

    .match-details__header__floating-tags__bottom {
      position: relative;
      left: -35px;
      top: -5px; }

    .bp3-tag {
      margin-right: 10px; } }

  .match-details__server-address {
    .bp3-control-group {
      margin-bottom: 15px;
      input {
        text-align: center; } } }

  .match-details__scenarios {
    margin-top: 5px; }

  .match-details__extra-info {
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    label {
      flex-grow: 1;
      padding-left: 10px;
      padding-right: 10px; }

    input {
      text-align: center; } } }

.alert-rule-set-item {
  margin-bottom: 10px;
  .alert-rule-set-item__field {
    width: 150px;
    span:not(.bp3-icon) {
      margin-left: 10px; } }

  .bp3-tag {
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; } }
